<script lang="ts" setup>
import { computed } from 'vue'
import VueMarkdown from 'vue-markdown-render'

const props = defineProps({
  isAssistant: { type: Boolean, required: false, default: false },
  text: { type: String, default: '' },
})

const componentClasses = computed(() => [
  props.isAssistant ? 'bg-secondary' : 'bg-accent',
  props.isAssistant ? '' : 'ml-auto',
])
</script>

<template>
  <div class="uno-725v7k" :class="componentClasses">
    <div
      class="uno-b3seu7"
      :class="props.isAssistant ? '-left-2 border-t-secondary' : '-right-2 border-t-accent'"
    />

    <p class="uno-zvxgfn">
      {{ isAssistant ? 'Andrea' : 'Sie' }}:
    </p>
    <p class="uno-twafj2">
      <vue-markdown v-if="isAssistant" :source="props.text" />
      <slot v-else>
        {{ props.text }}
      </slot>
    </p>
  </div>
</template>

<style></style>
