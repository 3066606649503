<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import { messages, onMessageEntered, toggleChatbox } from './utils'
import Bubble from './components/Bubble.vue'
import InputField from './components/InputField.vue'

const chatEl = ref<HTMLDivElement | null>(null)
const isLoading = ref(false)

const scrollToBottom = () => {
  setTimeout(() => {
    chatEl.value?.scrollTo({
      top: chatEl.value.scrollHeight,
      behavior: 'smooth',
    })
  }, 300)
}

watch(() => messages.value, (m) => {
  const lastMessage = m[m.length - 1]
  isLoading.value = !lastMessage.assistant
  scrollToBottom()
}, { deep: true })

onMounted(() => {
  scrollToBottom()
})
</script>

<template>
  <div class="uno-qhccz1 chat-box">
    <div class="uno-yab6gq">
      <div class="uno-40xlk8" @click="toggleChatbox">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="#fff" d="M24 9.4L22.6 8L16 14.6L9.4 8L8 9.4l6.6 6.6L8 22.6L9.4 24l6.6-6.6l6.6 6.6l1.4-1.4l-6.6-6.6L24 9.4z" /></svg>
      </div>
      <div ref="chatEl" class="uno-cyef1k custom-scrollbar">
        <bubble v-for="({ assistant, message }, idx) in messages" :key="idx" :is-assistant="assistant" :text="message" />
      </div>
      <div>
        <div class="uno-mzkcja" :class="isLoading ? 'opacity-100' : 'opacity-0'" />
        <input-field @submit="onMessageEntered" />
      </div>
    </div>
  </div>
</template>

<style>
body.show-chat-box .chat-box {
  @apply fixed flex z-800;
}
</style>
