import { createApp } from 'vue'
import App from './App.vue'
import AppInput from './AppInput.vue'
import './assets/styles/app.css'
import 'uno.css'

createApp(App).mount('#v4wp-app-vue')

const inputApp = createApp(AppInput)
if (document.getElementById('v4wp-ki-input'))
  inputApp.mount('#v4wp-ki-input')
