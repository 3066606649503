import { useStorage } from '@vueuse/core'

export const messages = useStorage('messages', [
  {
    assistant: true,
    message: 'Hallo, mein Name ist Andrea. Wie kann ich Ihnen helfen?',
  },
])

export const toggleChatbox = () => {
  const body = document.querySelector('body')
  if (!body)
    return
  if (body.classList.contains('show-chat-box'))
    body.classList.remove('show-chat-box')

  else
    body.classList.add('show-chat-box')
}

export const onMessageEntered = async (text: string) => {
  messages.value.push({
    assistant: false,
    message: text,
  })

  const prefix = window.location.href.includes('/ehnesnewwebsite/') ? '/ehnesnewwebsite' : ''

  const response = await fetch(`${prefix}/wp-json/ehnes/chat`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      question: text,
    }),
  })
  const result = await response.json()

  messages.value.push({
    assistant: true,
    message: result.answer ?? 'Ich habe leider keine Antwort auf Ihre Frage gefunden.',
  })
}
