<script lang="ts" setup>
import { computed, ref } from 'vue'

defineProps({
  green: { type: Boolean, default: false },
})

const emit = defineEmits(['submit'])
const inputEl = ref<HTMLDivElement | null>(null)
const input = ref('')
const isDirty = computed(() => input.value.length > 0)

const doWork = () => {
  if (!inputEl.value)
    return
  const body = document.querySelector('body')!
  if (!body.classList.contains('show-chat-box'))
    body.classList.add('show-chat-box')

  const target = inputEl.value
  const text = target.textContent?.trim()
  console.log(text)
  emit('submit', text)
  target.textContent = ''
}

const onKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    e.preventDefault()
    doWork()
  }
}

const onFocus = (e: Event) => {
  if (e.target) {
    const target = e.target as HTMLDivElement
    if (target.textContent?.trim() === 'Wie können wir Ihnen helfen?')
      target.textContent = ''
  }
}
const onBlur = (e: Event) => {
  if (e.target) {
    const target = e.target as HTMLDivElement
    if (target.textContent?.trim() === '')
      target.textContent = 'Wie können wir Ihnen helfen?'
  }
}
</script>

<template>
  <div class="uno-nnioyw" :class="[isDirty ? 'w-full' : 'w-80', green ? '!bg-primary' : 'bg-accent']" @click.stop="">
    <div class="uno-jl0m9i">
      <div class="i-solar-magnifer-outline" />
    </div>
    <div class="uno-ed1e2c">
      <div ref="inputEl" class="w-full py-3 text-sm outline-none" contenteditable @keydown="onKeyDown" @focus="onFocus" @blur="onBlur">
        Wie können wir Ihnen helfen?
      </div>
    </div>
    <div class="uno-1ndhvz">
      <div class="uno-mhl70e" @click="doWork">
        (KI)
      </div>
    </div>
  </div>
</template>

<style></style>
